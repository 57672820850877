.body {
  box-sizing: border-box;
  overflow-x: "auto";
}

@font-face {
  font-family: "MollenBold";
  /*Can be any text*/
  src: local("MollenBold"),
    url("../../fonts/mollen-bold.otf") format("otf");
}