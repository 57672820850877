@media only screen and (max-width: 550px) {
  :root {
    --apps_count: 1;
    --app_card_width:"500px"
  }
}

@media only screen and (min-width:550px) and (max-width: 800px) {
  :root {
    --apps_count: 2;
    --app_card_width:"200px"
  }
}

@media only screen and (min-width:800px) and (max-width: 1050px) {
  :root {
    --apps_count: 3;
    --app_card_width:"250px"
  }
}

@media only screen and (min-width:1050px) and (max-width: 1290px) {
  :root {
    --apps_count: 4;
    --app_card_width:"250px"
  }
}

@media only screen and (min-width:1290px) and (max-width: 1600px) {
  :root {
    --apps_count: 5;
    --app_card_width:"250px"
  }
}

@media only screen and (min-width: 1600px) {
  :root {
    --apps_count: 6;
    --app_card_width:"250px"
  }
}