.toolbar_colors {
  --colorCompoundBrandStroke: #FFF;
  /* --colorCompoundBrandBackgroundHover: #FFF; */
  --colorCompoundBrandStrokeHover:#FFF;
  --colorCompoundBrandStrokePress:#FFF;
  /* --colorNeutralStroke1Hover:#FFF; */
  /* --colorCompoundBrandBackgroundPress: #FFF; */
  /* --colorBrandStroke1:#FFF;
  --colorBrandStroke2:#FFF;
  --colorBrandStroke2Hover:#FFF;
  --colorBrandStroke2Press:#FFF; */
}